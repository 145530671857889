import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, DataGridTree } from '@miyagami-com/lsx-ui-components';
import {
  DataGridPremiumProps,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import useStyles from './useStyles';
import useGroupingColumn from './useGroupingColumn';
import useSportLine from './useSportLine';
import useRowTreeStyles from './useRowTreeStyles';
import useOnCellEditCommit from './useOnCellEditCommit';
import useBrandId from '../../../common/hooks/useBrandId';
import classNames from 'classnames';
import convertNodeToRows from './convertNodeToRows';
import { NavigationNode, RootState } from '../../../../types';
import DetailPanel from './DetailPanel';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import { useSelector } from 'react-redux';
import useColumns, { SportLineRow } from './useColumns';
import { fetchExchangeConfigs } from './fetchExchangeConfigs';
import { useQuery } from 'react-query';
import useSupabase from '../../../common/hooks/useSupabase';
import { DEFAULT_AMOUNT } from '../../../common/constants';

const rowsPerPageOptions = [10, 15, 20, 25, 30, 35, 40, 45, 50, 100];
const rowHeight = 32;
const defaultPageSize = 100;

const sortModel: GridSortModel = [{ field: 'id', sort: 'asc' }];

interface SportLineProps
  extends Omit<DataGridPremiumProps, 'columns' | 'rows'> {
  playerId?: string;
}

const SportLine: React.FC<SportLineProps> = (props) => {
  const { playerId, ...otherProps } = props;

  const apiRef = useGridApiRef();
  const brandId = useBrandId();
  const supabase = useSupabase();

  const rowClassName = useRowTreeStyles();
  const { isLoading: isSubmitting, onCellEditCommit } = useOnCellEditCommit({
    brandId,
    playerId,
    apiRef,
  });

  const { data: initialNode, isLoading } = useSportLine({
    brandId,
    playerId,
  });

  const { roles, uid: authUserId } = useSelector(
    (state: RootState) => state.user,
  );
  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const [nodes, setNodes] = useState<NavigationNode[]>([]);

  useEffect(() => {
    setNodes([]);
  }, [brandId]);

  const addNode = useCallback((newNode: NavigationNode) => {
    setNodes((prev) => [...prev, newNode]);
  }, []);

  useEffect(() => {
    if (isLoading || !initialNode) return;

    addNode(initialNode);
  }, [addNode, initialNode, isLoading]);

  const initialRows = useMemo(() => {
    return convertNodeToRows({
      node: nodes,
      parentRow: null,
    });
  }, [nodes]);

  const countryRows = useMemo(() => {
    return initialRows.filter((row) => row.type === 'COUNTRY');
  }, [initialRows]);

  const countryExchangeConfigsParams = useMemo(() => {
    return {
      refs: countryRows.map((row) => row.id),
      brandId,
      authUserId,
      isSystemAdmin,
      playerId,
    };
  }, [countryRows, brandId, authUserId, isSystemAdmin, playerId]);

  const {
    data: countryExchangeConfigs,
    isLoading: countryExchangeConfigsLoading,
  } = useQuery({
    queryKey: ['countryExchangeConfigs', countryExchangeConfigsParams],
    queryFn: () => fetchExchangeConfigs(supabase, countryExchangeConfigsParams),
    enabled:
      !!countryExchangeConfigsParams.refs &&
      !!countryExchangeConfigsParams.brandId,
  });

  useEffect(() => {
    const disabledRows: SportLineRow[] = [];

    initialRows.forEach((row) => {
      // Bind config to country row
      const countryConfig = countryExchangeConfigs?.get(row.id);

      if (countryConfig) {
        const { disabled_by, player_id, priority, risk_level, is_disabled } =
          countryConfig;

        row.disabledBy = disabled_by;
        row.playerId = player_id;
        row.priority = priority || DEFAULT_AMOUNT;
        row.riskLevel = risk_level || 'low';
        row.isEnabled = !is_disabled;
      }

      // Hide rows that are disabled by a higher role or should be hidden by playerId
      const disabledBy = isSystemAdmin ? 'systemAdmin' : authUserId;
      const shouldHideConfig =
        playerId != null &&
        row.playerId == null &&
        !row.isEnabled &&
        row.disabledBy === disabledBy;

      const disabledByHigherRole =
        !row.isEnabled && row.disabledBy !== authUserId;

      if (!((isSystemAdmin || !disabledByHigherRole) && !shouldHideConfig)) {
        apiRef.current.updateRows([{ id: row.id, _action: 'delete' }]);
        disabledRows.push(row);
      }
    });

    // Hide children of disabled rows (particularly for country rows)
    initialRows.forEach((row) => {
      if (
        disabledRows.find((disabledRow) => disabledRow.id === row.parentRow?.id)
      ) {
        apiRef.current.updateRows([{ id: row.id, _action: 'delete' }]);
      }
    });
  }, [
    initialRows,
    apiRef,
    countryExchangeConfigs,
    isSystemAdmin,
    authUserId,
    playerId,
  ]);

  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const classes = useStyles({});
  const groupingColDef = useGroupingColumn();

  const columns = useColumns(isSubmitting);
  const className = classNames(classes.dataGridPadding);

  return (
    <Box height={1} display="flex">
      <Box flexGrow={1}>
        <DataGridTree
          {...otherProps}
          onCellEditCommit={onCellEditCommit}
          sortModel={sortModel}
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          autoHeight
          disableSelectionOnClick
          loading={isLoading || isSubmitting || countryExchangeConfigsLoading}
          rows={initialRows}
          apiRef={apiRef}
          rowHeight={rowHeight}
          className={className}
          getDetailPanelContent={(params) => (
            <>
              <DetailPanel
                {...params}
                addNode={addNode}
                playerId={playerId}
                brandId={brandId}
              />
            </>
          )}
          getTreeDataPath={(row) => row?.path.split('/')}
          columns={columns}
          getDetailPanelHeight={() => rowHeight}
          groupingColDef={groupingColDef}
          getCellClassName={(params) => rowClassName(params.row.path)}
          getRowClassName={(params) => rowClassName(params.row.path)}
        />
      </Box>
    </Box>
  );
};

export default SportLine;
