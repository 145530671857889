import { CircularProgress, Typography } from '@mui/material';
import DataGridTable from '../../Unknown/DataGridTable';
import { EventHistory } from '../ProfitLossEventHistoryPage/useGetEventHistory';
import messages from './messages';
import useColumns from './useColumns';
import useStyles from './useStyles';

type EventHistoryType = 'more' | 'less';

interface PLEventHistoryListProps {
  rows: EventHistory[];
  type: EventHistoryType;
  isLoading: boolean;
}

const ProfitLossEventHistoryList = (
  props: PLEventHistoryListProps,
): JSX.Element => {
  const { rows, type, isLoading } = props;

  const columns = useColumns();
  const classes = useStyles();

  const tableTitle =
    type === 'more'
      ? messages.mostProfitable.defaultMessage
      : messages.mostLosses.defaultMessage;

  const HeaderTitle = () => <Typography variant="h4">{tableTitle}</Typography>;

  return (
    <>
      <DataGridTable
        loading={isLoading}
        columns={columns}
        rows={rows}
        classes={classes}
        disableSelectionOnClick={true}
        pageSize={rows.length}
        autoHeight={true}
        rowHeight={35}
        headerHeight={25}
        tableKey="profitLossEventHistory"
        hideFooterRowCount
        hideFooterPagination
        hideFooterSelectedRowCount
        showColumnRightBorder
        showCellRightBorder
        experimentalFeatures={{ columnGrouping: false }}
        components={{
          Toolbar: HeaderTitle,
          LoadingOverlay: CircularProgress,
        }}
      />
    </>
  );
};

export default ProfitLossEventHistoryList;
