import { useMutation } from 'react-query';
import { useFirebaseApp } from 'reactfire';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { DEFAULT_REGION } from '../constants';

interface UseUpdateBetsEventOpenDateParams {
  brandId: string;
  marketId: string;
  eventOpenDate: Date;
}

const useUpdateBetsEventOpenDate = (): ((
  params: UseUpdateBetsEventOpenDateParams,
) => Promise<void>) => {
  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const updateExchangeBetByFilter = httpsCallable(
    functions,
    'back-exchangeBet-updateExchangeBetByFilter',
  );

  const mutation = useMutation(
    async (params: UseUpdateBetsEventOpenDateParams) => {
      const { brandId, marketId, eventOpenDate } = params;
      await updateExchangeBetByFilter({
        brandId,
        filter: { marketId },
        payload: { event_open_date: eventOpenDate.toISOString() },
      });
    },
  );

  return mutation.mutateAsync;
};

export default useUpdateBetsEventOpenDate;
