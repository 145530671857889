import { useFirebaseApp } from 'reactfire';
import { PublicPLReport, ProfitLossReportLevel } from '../../../types';
import { DEFAULT_REGION } from '../constants';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface ReportParams {
  reportLevel: ProfitLossReportLevel;
  from: string;
  to: string;
  brandId: string | null;
  parentId: string | null;
}

type FetchReports = (params: ReportParams) => Promise<PublicPLReport[] | null>;

const useProfitLossReport = (): FetchReports => {
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const fetchReport = async (params: ReportParams) => {
    const fetchFunction = httpsCallable(functions, 'back-profitLoss-report');

    const response = await fetchFunction(params);
    const reports = response.data as PublicPLReport[] | null;

    return reports;
  };

  return fetchReport;
};

export default useProfitLossReport;
