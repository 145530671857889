import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BackOfficeUser, RootState } from '../../../types';
import { Player } from '../../../types/supabase';
import getDefaultSenderList from '../getDefaultSenderList';
import checkIsSystemAdmin from '../checkIsSystemAdmin';

export type SenderList = {
  value?: string;
  label?: string;
};

interface UseSenderTransactionListParams {
  brandId: string;
  user?: Player | BackOfficeUser;
}

const useSenderTransactionList = (
  params: UseSenderTransactionListParams,
): SenderList[] => {
  const { brandId, user } = params;

  const { users, brands } = useSelector((state: RootState) => state.globalData);

  const { uid: authUserId, roles } = useSelector(
    (state: RootState) => state.user,
  );

  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const senderList: SenderList[] = useMemo(() => {
    const list: SenderList[] = [];

    if (brandId && brands && users && user) {
      const { defaultSenderList } = getDefaultSenderList({
        authUserId,
        users,
        brandId,
        currentUser: user,
      });

      list.push(...defaultSenderList);
    }

    if (isSystemAdmin) {
      list.push({
        value: authUserId,
        label: 'System Admin',
      });
    }

    return list;
  }, [brandId, brands, users, user, authUserId, isSystemAdmin]);

  return senderList;
};

export default useSenderTransactionList;
