import { FC, useState, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ExchangeBetOverviewList, {
  ExtendedDataGridProps,
} from '../ExchangeBetOverviewList';
import ExchangeBetOverviewHeader from '../ExchangeBetOverviewHeader';

import normalizeBetListData from './normalizeBetListData';
import {
  BETTING_OVERVIEW_PAGE_SIZE,
  DEFAULT_PAGE,
  EXTENDED_EXCHANGE_BET_SELECT,
} from '../../../common/constants';
import useExchangeBets from '../../../common/hooks/useExchangeBets';
import { ExtendedExchangeBet } from '../../../../types';
import useExtendedExchangeBetStatusMap from '../../../common/hooks/useExtendedExchangeBetStatusMap';
import type { DateRange } from '@mui/x-date-pickers-pro';
import { tomorrow } from '../../ProfitLoss/ProfitLossReportPage/reportPeriodData';
import qs from 'qs';

type QueryParams = {
  brandId: string;
  userId: string;
};

interface ExchangeBetOverviewProps {
  pageType: 'player' | 'brand';
}

interface QueryString {
  fromDate?: Date | null;
  toDate?: Date | null;
}

const ExchangeBetOverview: FC<ExchangeBetOverviewProps> = ({ pageType }) => {
  const { brandId, userId }: QueryParams = useParams();
  const { search } = useLocation();

  const history = useHistory();

  const parsedString = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as unknown as QueryString;

  const { fromDate, toDate } = parsedString;

  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState<number>(BETTING_OVERVIEW_PAGE_SIZE);
  const [dateRange, setDateRange] = useState<DateRange<Date>>([
    fromDate ? new Date(fromDate) : new Date(),
    toDate ? new Date(toDate) : tomorrow,
  ]);

  const onDateRangeChange = useCallback(
    (newDateRange: DateRange<Date>) => {
      setDateRange(newDateRange);
      const [start, end] = newDateRange;

      // Update URL query params when date range changes
      if (start && end) {
        const queryParams = qs.parse(search, {
          ignoreQueryPrefix: true,
        });
        const newSearch = qs.stringify(
          {
            ...queryParams,
            fromDate: start.toISOString(),
            toDate: end.toISOString(),
          },
          { addQueryPrefix: true },
        );

        history.push({ search: newSearch });
      }
    },
    [history, search],
  );

  const exchangeSizeStatusMap = useExtendedExchangeBetStatusMap();

  const isPlayer = pageType === 'player';

  const { refetch, isFetching, data } = useExchangeBets<ExtendedExchangeBet>({
    page,
    size: pageSize,
    brandId,
    playerId: userId,
    select: EXTENDED_EXCHANGE_BET_SELECT,
    count: 'estimated',
    dateRange,
  });

  const normalizedExchangeBets = normalizeBetListData({
    exchangeBets: data?.data || [],
    exchangeSizeStatusMap,
  });

  const onPageChange = useCallback(
    (newPage: number) => {
      setPage(newPage);
    },
    [setPage],
  );

  const onPageSizeChange = useCallback(
    (newPage: number) => {
      setPageSize(newPage);
    },
    [setPageSize],
  );

  const dataGridProps: ExtendedDataGridProps = {
    loading: isFetching,
    rows: normalizedExchangeBets,
    pageSize,
    onPageSizeChange,
    onPageChange,
    page,
    rowCount: data?.count || 0,
  };
  return (
    <>
      <ExchangeBetOverviewHeader
        exchangeBets={data?.data || []}
        dateRange={dateRange}
        setDateRange={onDateRangeChange}
        hideBrandFilter={isPlayer}
      />

      <ExchangeBetOverviewList
        dataGridProps={dataGridProps}
        isPlayerPage={isPlayer}
        pageType={pageType}
        refetch={refetch}
      />
    </>
  );
};

export default ExchangeBetOverview;
