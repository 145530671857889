import { DateRange } from '@mui/x-date-pickers-pro';
import { useQuery } from 'react-query';
import usePLReport from '../../../common/hooks/useDetailedPLReport';
import { UIContext } from '@miyagami-com/lsx-ui-components';
import { useContext, useState } from 'react';
import commonMessages from '../../../common/messages';
import { useIntl } from 'react-intl';
import { endOfDay } from 'date-fns';
import { ProfitLossReportLevel } from '../../../../types';
import normalizeReports, { PLReportRow } from './normalizeReports';
import { convertToUTCString } from '../../../common/hooks/useExchangeBets';

interface UseRowsParams {
  reportLevel: ProfitLossReportLevel;
  brandId: string;
  agentId: string | null;
  dateRange: DateRange<Date>;
}

const useRows = (params: UseRowsParams): typeof returnParams => {
  const { reportLevel, brandId, agentId, dateRange } = params;

  const reportsState = useState<PLReportRow[]>([]);

  const [, setReports] = reportsState;

  const { setAlert } = useContext(UIContext);

  const intl = useIntl();

  const fetchReports = usePLReport();

  const queryResult = useQuery(
    ['report', reportLevel, brandId, agentId, dateRange],
    async () => {
      const currentDate = new Date();
      const endOfCurrentDay = endOfDay(currentDate);

      const fromDate = dateRange[0] ? new Date(dateRange[0]) : currentDate;
      const endDate = dateRange[1] ? new Date(dateRange[1]) : endOfCurrentDay;

      const from = convertToUTCString({ date: fromDate, endOfDay: false });
      const to = convertToUTCString({ date: endDate, endOfDay: true });

      const isSystemLevel = reportLevel === 'systemAdmin';
      const reportBrandId = isSystemLevel ? null : brandId;

      const reports = await fetchReports({
        brandId: reportBrandId,
        parentId: agentId,
        from,
        to,
        reportLevel,
      });

      const rows = normalizeReports({ reports, reportLevel });

      return rows;
    },
    {
      onError: () => {
        setAlert({
          message: intl.formatMessage(commonMessages.fetchErrorMessage),
          severity: 'error',
          show: true,
        });
      },
      onSuccess: (plReports) => {
        if (!plReports) return;

        setReports(plReports);
      },
    },
  );

  const returnParams = {
    queryResult,
    reportsState,
  };

  return returnParams;
};

export default useRows;
