import { useQuery, UseQueryResult } from 'react-query';
import useSupabase from './useSupabase';

interface UsePlayerTransactionStatsParams {
  playerId: string;
  brandId: string;
}

interface TransactionStats {
  withdrawTotal: number;
  depositTotal: number;
  totalDepositAndWithdraw: number;
}

const usePlayerTransactionStats = (
  params: UsePlayerTransactionStatsParams,
): UseQueryResult<TransactionStats, Error> => {
  const { playerId, brandId } = params;
  const supabase = useSupabase();

  return useQuery<TransactionStats, Error>(
    ['playerTransactionStats', playerId, brandId],
    async () => {
      const { data, error } = await supabase
        .from('player_transaction_stats_view')
        .select(
          `
          amount,
          participants,
          sender_transaction_id,
          receiver_transaction_id,
          transaction_id,
          sender_role,
          brand_id,
          note
        `,
        )
        .eq('brand_id', brandId)
        .like('participants', `%${playerId}%`);

      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      if (error) throw error;

      const stats = (data || []).reduce(
        (acc, transaction) => {
          const senderId = transaction.participants?.split('/')[0];
          const amount = transaction.amount || 0;

          if (senderId === playerId) {
            acc.withdrawTotal -= amount;
          } else {
            acc.depositTotal += amount;
          }

          return acc;
        },
        { withdrawTotal: 0, depositTotal: 0 },
      );
      return {
        withdrawTotal: Number(stats.withdrawTotal.toFixed(2)),
        depositTotal: Number(stats.depositTotal.toFixed(2)),
        totalDepositAndWithdraw: Number(
          (stats.depositTotal + stats.withdrawTotal).toFixed(2),
        ),
      };
    },
  );
};

export default usePlayerTransactionStats;
