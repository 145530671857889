import { getFunctions, httpsCallable } from 'firebase/functions';
import { useFirebaseApp } from 'reactfire';
import { DEFAULT_REGION } from '../../../common/constants';
import { UseQueryResult, useQuery } from 'react-query';

interface GetEventHistoryParams {
  userId: string;
  startDate: string;
  endDate: string;
  brandId: string;
}

export interface EventHistory {
  id: string;
  event: string;
  sport: string;
  date: string;
  market: string;
  marketId: string;
  totalReturn: number;
  [key: string]: string | number;
}

interface EventHistoryReturn {
  mostProfitable: EventHistory[];
  leastProfitable: EventHistory[];
}

const isTodayDate = (date: Date): boolean => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};

const getDefaultDateRange = (): { start: string; end: string } => {
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0);

  const todayEnd = new Date();
  todayEnd.setHours(23, 59, 59, 999);

  return {
    start: todayStart.toISOString(),
    end: todayEnd.toISOString(),
  };
};

const useGetEventHistory = (
  params: GetEventHistoryParams,
): UseQueryResult<EventHistoryReturn> => {
  const { userId, startDate, endDate, brandId } = params;

  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase, DEFAULT_REGION);

  const getEventHistoryFunct = httpsCallable(
    functions,
    'back-exchangeBet-callGetEventHistoryPL',
  );

  // sanitise date & provide default
  let defaultStartDateStr = null as string | null;
  let defaultEndDateStr = null as string | null;

  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  if (isTodayDate(startDateObj) && isTodayDate(endDateObj)) {
    const defaultDateRange = getDefaultDateRange();
    defaultStartDateStr = defaultDateRange.start;
    defaultEndDateStr = defaultDateRange.end;
  }

  const sanitisedEndDate = new Date(endDate);
  sanitisedEndDate.setHours(23, 59, 59, 999);
  const sanitisedEndDateStr = sanitisedEndDate.toISOString();

  return useQuery({
    queryKey: ['eventHistory', userId, startDate, endDate],
    queryFn: async () => {
      const response = await getEventHistoryFunct({
        userId,
        startDate: defaultStartDateStr || startDate,
        endDate: defaultEndDateStr || sanitisedEndDateStr,
        brandId,
      });

      const eventHistory = response.data as EventHistory[];

      const mostProfitable = eventHistory
        .filter((entry) => entry.totalReturn > 0)
        .sort((a, b) => b.totalReturn - a.totalReturn);

      const leastProfitable = eventHistory
        .filter((entry) => entry.totalReturn <= 0)
        .sort((a, b) => a.totalReturn - b.totalReturn);

      return { mostProfitable, leastProfitable };
    },
  });
};

export default useGetEventHistory;
