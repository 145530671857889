import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { DateRange } from '@mui/x-date-pickers-pro';
import useBrandId from '../../../common/hooks/useBrandId';
import DefaultLayout from '../../Unknown/DefaultLayout';
import useTranslations from './useTranslations';
import { ProfitLossReportQuery } from '../ProfitLossReportList';
import OverviewLayout from '../../Unknown/OverviewLayout';
import useDataPicker from './useDataPicker';
import messages from './messages';
import { localToUTC, makeLocalAppearUTC } from '../ProfitLossReportList/utils';
import ProfitLossEventHistoryList from '../ProfitLossEventHistoryList';
import useGetEventHistory from './useGetEventHistory';

const ADMIN_ID = 'systemAdmin';
const OWNER_ID = 'owner';

const ProfitLossEventHistoryPage = (): JSX.Element => {
  const brandId = useBrandId();

  const { reportLevel, agentId } = useParams<ProfitLossReportQuery>();

  const isOwner = reportLevel === 'brand';
  const userId = isOwner ? OWNER_ID : ADMIN_ID;

  const { path, tableName } = useTranslations({
    brandId,
    agentId,
    reportLevel,
  });

  // overview layout builder
  const intl = useIntl();

  const { onDatePickerChange, changeReportDateRange, datePickerValues } =
    useDataPicker();

  const utcFrom =
    typeof datePickerValues[0] === 'string'
      ? new Date(datePickerValues[0]).toISOString()
      : datePickerValues[0]?.toISOString();

  const utcTo =
    typeof datePickerValues[1] === 'string'
      ? new Date(datePickerValues[1]).toISOString()
      : datePickerValues[1]?.toISOString();

  // get events
  const { data, isLoading } = useGetEventHistory({
    userId: agentId || userId,
    startDate: utcFrom || '',
    endDate: utcTo || '',
    brandId,
  });

  const { mostProfitable, leastProfitable } = data || {};

  return (
    <DefaultLayout path={path}>
      <OverviewLayout
        headerProps={{
          name: tableName,
          from: utcFrom,
          to: utcTo,
          dateRangeProps: {
            localizationProviderProps: {
              localeText: {
                start: intl.formatMessage(messages.from),
                end: intl.formatMessage(messages.to),
              },
            },
            dateRangePickerProps: {
              value: makeLocalAppearUTC(datePickerValues),
              onChange: (newValue) => {
                const newValueUTC = localToUTC(
                  newValue as DateRange<Date>,
                  datePickerValues[1],
                );
                onDatePickerChange(newValueUTC);
              },
              onClose: changeReportDateRange,
              closeOnSelect: false,
            },
          },
        }}
      >
        <ProfitLossEventHistoryList
          isLoading={isLoading}
          rows={mostProfitable || []}
          type={'more'}
        />
        <ProfitLossEventHistoryList
          isLoading={isLoading}
          rows={leastProfitable || []}
          type={'less'}
        />
      </OverviewLayout>
    </DefaultLayout>
  );
};

export default ProfitLossEventHistoryPage;
