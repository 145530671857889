import { ProfitLossReportLevel, UserState } from '../../../../types';
import { PL_EVENT_HISTORY_BASE_URL } from '../../../common/constants';

const generateUrl = ({
  level,
  agentId,
  brandId,
}: {
  level: ProfitLossReportLevel;
  agentId?: string;
  brandId?: string;
}) => {
  const brandPath = brandId ? `b/${brandId}` : '';

  const url = [brandPath, PL_EVENT_HISTORY_BASE_URL, level, agentId]
    .filter((path) => !!path)
    .join('/');

  return url;
};

export const getReportLevelByPermission = ({
  user,
  brandId,
}: {
  user: UserState;
  brandId: string;
}): ProfitLossReportLevel | null => {
  const permissionMap = user.permissionMap[brandId];

  if (permissionMap?.systemAdmin?.read) return 'systemAdmin';
  if (permissionMap?.brand?.read) return 'brand';
  if (permissionMap?.superAgent?.read) return 'superAgent';
  if (permissionMap?.masterAgent?.read) return 'masterAgent';
  if (permissionMap?.agent?.read) return 'agent';

  return null;
};

const getReportUrlByPermission = ({
  user,
  brandId,
}: {
  user: UserState;
  brandId: string;
}): string => {
  const reportLevel = getReportLevelByPermission({
    user,
    brandId,
  });

  const urlByPermission: Record<ProfitLossReportLevel, string> = {
    systemAdmin: generateUrl({ level: 'systemAdmin' }),
    brand: generateUrl({ level: 'brand', brandId }),
    superAgent: generateUrl({
      level: 'superAgent',
      brandId,
      agentId: user.uid,
    }),
    masterAgent: generateUrl({
      level: 'masterAgent',
      brandId,
      agentId: user.uid,
    }),
    agent: generateUrl({ level: 'agent', brandId, agentId: user.uid }),
    player: generateUrl({ level: 'agent', brandId, agentId: user.uid }),
  };

  const url = reportLevel ? urlByPermission[reportLevel] : '/';
  return `/${url}`;
};

export default getReportUrlByPermission;
