import { FC, useCallback, useContext } from 'react';
import { CollapseBox, UIContext } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import { useFirebaseApp } from 'reactfire';

import {
  BackOfficeUser,
  TransactionParticipantItemType,
} from '../../../../types';
import {
  DEFAULT_REGION,
  SYSTEM_TRANSACTION_PARTICIPANT,
} from '../../../common/constants';
import TransactionCreateForm, {
  SenderList,
  OnSubmitValues,
  OnSubmitActions,
} from '../TransactionCreationForm';

import messages from './messages';
import useCheckIsCanCreateTransaction from '../../../common/hooks/useCheckIsCanCreateTransaction';
import { Player } from '../../../../types/supabase';
import { useQueryClient } from 'react-query';
import { getFunctions, httpsCallable } from 'firebase/functions';
import useCheckIsTransactionUserAdmin from '../../../common/hooks/useCheckIsTransactionUserAdmin';

type SenderOrReceiver = { id: string; type: TransactionParticipantItemType };

type UsersData = {
  [key in 'transfer' | 'withdraw']: {
    sender: SenderOrReceiver;
    receiver: SenderOrReceiver;
  };
};

interface TransactionCreationProps {
  type: 'transfer' | 'withdraw';
  currentUser: Player | BackOfficeUser;
  userType: 'player' | 'backofficeUser';
  senderList: SenderList[];
  brandId: string;
}

const TransactionCreation: FC<TransactionCreationProps> = (params) => {
  const { type, currentUser, userType, senderList, brandId } = params;

  const intl = useIntl();

  const { setAlert } = useContext(UIContext);

  const firebase = useFirebaseApp();

  const functions = getFunctions(firebase, DEFAULT_REGION);

  const checkIsCanCreateTransaction = useCheckIsCanCreateTransaction();

  const checkIsTransactionUserAdmin = useCheckIsTransactionUserAdmin();

  const queryClient = useQueryClient();

  const onCreateTransaction = useCallback(
    async (values: OnSubmitValues, actions: OnSubmitActions) => {
      const createTransactionFunction = httpsCallable(
        functions,
        'back-transaction-createTransaction',
      );

      const isSystemSender = await checkIsTransactionUserAdmin({
        userId: values.sender,
      });

      const isSystemReceiver = await checkIsTransactionUserAdmin({
        userId: values.receiver,
      });

      const senderParticipantItemType = isSystemSender ? 'system' : userType;

      const receiverParticipantItemType = isSystemReceiver
        ? 'system'
        : userType;

      const usersData: UsersData = {
        transfer: {
          sender: { id: values.sender, type: senderParticipantItemType },
          receiver: {
            id: currentUser.id,
            type: receiverParticipantItemType,
          },
        },
        withdraw: {
          sender: { id: currentUser.id, type: senderParticipantItemType },
          receiver: {
            id: values.receiver,
            type: receiverParticipantItemType,
          },
        },
      };

      try {
        // prevent withdrawal and bet placement to happen at the same time
        const randDelay = Math.random() * (12000 - 8000) + 8000;
        const delay = (ms: number) =>
          new Promise((resolve) => setTimeout(resolve, ms));

        await delay(randDelay);
        const isCanCreateTransaction = await checkIsCanCreateTransaction({
          amount: values.amount,
          userId: isSystemSender
            ? SYSTEM_TRANSACTION_PARTICIPANT
            : usersData[type].sender.id,
        });

        if (!isCanCreateTransaction) {
          setAlert({
            show: true,
            severity: 'info',
            message: intl.formatMessage(messages.errorBalance),
          });

          return;
        }

        await createTransactionFunction({
          brandId,
          sender: usersData[type].sender,
          receiver: usersData[type].receiver,
          amount: values.amount,
          note: values.note,
        });

        setAlert({
          show: true,
          severity: 'success',
          message: intl.formatMessage(messages.successMessage),
        });
        actions.resetForm();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setAlert({
          show: true,
          severity: 'error',
          message: intl.formatMessage(messages.errorMessage),
        });
      } finally {
        queryClient.refetchQueries(['userTransactions', currentUser.id]);
        queryClient.refetchQueries(['userStat', currentUser.id]);
      }
    },
    [
      brandId,
      checkIsCanCreateTransaction,
      checkIsTransactionUserAdmin,
      currentUser.id,
      functions,
      intl,
      queryClient,
      setAlert,
      type,
      userType,
    ],
  );

  const transactionData = {
    transfer: {
      senderName: '',
      receiverName: currentUser.username,
      message: messages.transferCredit,
    },
    withdraw: {
      senderName: currentUser.username,
      receiverName: '',
      message: messages.withdrawCredit,
    },
  };

  const initialValues = {
    sender: transactionData[type].senderName,
    receiver: transactionData[type].receiverName,
    amount: 0,
    note: '',
  };

  return (
    <CollapseBox
      label={intl.formatMessage(transactionData[type].message)}
      defaultValue
    >
      <TransactionCreateForm
        type={type}
        initialValues={initialValues}
        onCreateTransaction={onCreateTransaction}
        senderList={senderList}
      />
    </CollapseBox>
  );
};

export default TransactionCreation;
