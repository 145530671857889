import {
  Box,
  CollapseBox,
  Grid,
  ModalCenter,
  Typography,
} from '@miyagami-com/lsx-ui-components';
import ClosableBox from '../Unknown/ClosableBox';
import AdditionalDetailBox, {
  AdditionalDetailBoxProps,
} from './AdditionalDetailBox';
import HandicapMarketSnapshot from './RiskBetsHandicap';
import DefaultMarketSnapshot from './RiskBetsDefault';
import useBetSnapshot from './useBetSnapshot';
import formatData from './formatData';
import { GOAL_LINE_NAME, HANDICAP_NAME } from '../../common/constants';

interface BetSnapshotModalProps {
  isOpen: boolean;
  onClose: () => void;
  betId: number;
  marketName: string;
  selectionId: number;
  selectionName: string;
  eventOpenDateTime: string;
  betType: string;
}

const BetSnapshotModal = (props: BetSnapshotModalProps): JSX.Element | null => {
  const {
    isOpen,
    onClose,
    betId,
    marketName,
    selectionId,
    selectionName,
    eventOpenDateTime,
    betType,
  } = props;

  const { snapshotData, additionalDetails, handicapSnapshot } =
    useBetSnapshot(betId);

  if (!snapshotData || !additionalDetails) return null;

  const { ex: defaultSnapshot, totalMatched } = snapshotData;
  const { sportName, eventName, competitionName } = additionalDetails;

  const isHandicapMarket = marketName.includes(HANDICAP_NAME);
  const isGoalLineMarket = marketName.includes(GOAL_LINE_NAME);

  const { formattedSelectionName, formattedEventName, formattedEventDateTime } =
    formatData({
      selectionName,
      eventName,
      competitionName,
      eventOpenDateTime,
    });

  const additionalDetailsMap: AdditionalDetailBoxProps[] = [
    { title: 'Sport', value: sportName },
    { title: 'Event', value: formattedEventName },
    { title: 'Market Type', value: marketName },
    { title: 'Selection', value: formattedSelectionName },
    { title: 'Event Start Date & Time', value: formattedEventDateTime },
    { title: 'Bet Type', value: betType },
  ];

  return (
    <ModalCenter open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: 380, sm: 620, md: 940 },
          p: 2,
        }}
      >
        <ClosableBox label={'Bet snapshot detail'} onClose={() => onClose()}>
          <Grid container spacing={2} p={3}>
            {isHandicapMarket || isGoalLineMarket ? (
              <HandicapMarketSnapshot
                runnersSnapshot={handicapSnapshot}
                eventName={eventName}
                selectionId={selectionId}
                selectionName={selectionName}
              />
            ) : (
              <DefaultMarketSnapshot snapshot={defaultSnapshot} />
            )}
            <Grid item xs={12} mt={2}>
              {!isHandicapMarket && !isGoalLineMarket && (
                <Typography variant={'h3'} mb={3}>
                  Total Matched: {totalMatched}
                </Typography>
              )}
              <CollapseBox label="Additional details" defaultValue>
                <Box p={3}>
                  {additionalDetailsMap.map(({ title, value }, index) => (
                    <AdditionalDetailBox
                      key={index}
                      title={title}
                      value={value}
                    />
                  ))}
                </Box>
              </CollapseBox>
            </Grid>
          </Grid>
        </ClosableBox>
      </Box>
    </ModalCenter>
  );
};

export default BetSnapshotModal;
