import Big from 'big.js';
import { colors } from './theme';
import { BackofficeUserRole } from '../../types';

const LIMIT_LEVELS = {
  low: {
    label: 'Low risk',
    color: colors.successMain,
  },
  medium: {
    label: 'Medium risk',
    color: colors.warningLight,
  },
  high: {
    label: 'High risk',
    color: colors.errorMain,
  },
};

const RISK_LEVELS = {
  low: {
    label: 'Low',
    color: colors.successMain,
  },
  medium: {
    label: 'Medium',
    color: colors.warningLight,
  },
  high: {
    label: 'High',
    color: colors.errorMain,
  },
};

const PLAYER_ROLE = {
  value: 'player',
  label: 'Player',
};

const SYSTEM_ADMIN_ROLE = 'systemAdmin';
const BRAND_OWNER_ROLE = 'owner';

const SUPER_AGENT_ROLE = 'superAgent';
const MASTER_AGENT_ROLE = 'masterAgent';
const AGENT_ROLE = 'agent';

const USER_ROLES = [
  {
    value: SYSTEM_ADMIN_ROLE,
    label: 'System admin',
  },
  {
    value: BRAND_OWNER_ROLE,
    label: 'Brand owner',
  },
  {
    value: SUPER_AGENT_ROLE,
    label: 'Super Agent',
  },
  {
    value: MASTER_AGENT_ROLE,
    label: 'Master Agent',
  },
  {
    value: AGENT_ROLE,
    label: 'Agent',
  },
  {
    value: 'support',
    label: 'Support',
  },
  {
    value: 'marketing',
    label: 'Marketing',
  },
  {
    value: 'finance',
    label: 'Finance',
  },
];

type UserRoleMap = Record<BackofficeUserRole, string>;

const USER_ROLE_MAP = USER_ROLES.reduce<UserRoleMap>((map, userRole) => {
  return {
    ...map,
    [userRole.value]: userRole.label,
  };
}, {} as UserRoleMap);

const WIDGET_POSITIONS = {
  rightSidebar: {
    label: 'Right sidebar',
  },
  topBar: {
    label: 'Top bar',
  },
  externalRegistration: {
    label: 'External registration',
  },
};

const INITIAL_WIDGET_STATUS = 'draft';

const WIDGET_STATUS = {
  draft: {
    label: 'Draft',
    color: colors.warningMain,
  },
  published: {
    label: 'Published',
    color: colors.successMain,
  },
  archive: {
    label: 'Archive',
    color: colors.errorMain,
  },
};

const DEFAULT_DATE_FORMAT = 'dd-LL-yyyy';
const TWELVE_HOUR_TIME_FORMAT = 'h:mm a';
const DEFAULT_TIME_FORMAT = 'HH:mm';
const DEFAULT_DATE_TIME_FORMAT = `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;

const DEFAULT_REGION = 'europe-west2';

const IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/png'];

const AGENTS_ROLES = [SUPER_AGENT_ROLE, MASTER_AGENT_ROLE, AGENT_ROLE];

const REG_EXP_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;

const REG_EXP_NUMBER_ALPHABETS = /^[A-Za-z0-9]*$/;

const REG_EXP_PHONE_NUMBER = /^\+[1-9]\d{1,14}$/;

const BRAND_PENDING_ROLE = 'brandPending//';

const DEFAULT_CURRENCY = 'EUR';

const CURRENCY_SYMBOL = {
  [DEFAULT_CURRENCY]: '€',
};

const EXCHANGE_SETTINGS_TYPES = {
  sports: 'bySportId',
  leagues: 'byLeagueId',
  marketTypes: 'byMarketTypeId',
  events: 'byEventId',
};

const DEFAULT_ROWS_PER_PAGE_OPTIONS = [5, 10, 15, 25, 50, 100];

const DEFAULT_CHUNK_SIZE = 200;
const DEFAULT_LIMIT_RISK_BETS = 1000;

const DEFAULT_PRIORITY = 0;
const DEFAULT_LIMIT_LEVEL = 'low';
const DEFAULT_AMOUNT = 0;

const DATAGRID_DEBOUNCE_MS = 500;

const NETWORK_TREE_ROWS = {
  newUser: {
    id: 'newUser',
    label: 'New user',
  },
  total: {
    id: 'total',
    label: 'Total',
  },
  brand: {
    id: 'brand',
    label: 'System',
  },
};
const PLAYER_CONFIG_NAME = 'configByBrandIdAndPlayerId';
const BACKOFFICE_USER_CONFIG_NAME = 'configByBrandIdAndBackofficeUserId';

const ONLY_POSITIVE_AMOUNT_ERROR = 'amount will be able to only positive';

const MAX_PT_RATE = 1;
const MAX_COMMISSION_RATE = 0.1;

const UNSETTLED_BET_DEFAULT_VALUE = '-';

const DEFAULT_LIMIT = 0;

const DEFAULT_TRANSACTION_CHUNK_SIZE = 1000;

const DEFAULT_STEP_SIZE = 0.01;

const DOMAIN_STATUSES = {
  connecting: {
    label: 'Connecting',
    color: colors.grey400,
  },
  misconfigured: {
    label: 'Misconfigured',
    color: colors.warningMain,
  },
  verification: {
    label: 'Verification',
    color: colors.warningMain,
  },
  disabled: {
    label: 'Disabled',
    color: colors.errorMain,
  },
  connected: {
    label: 'Connected',
    color: colors.successMain,
  },
};

const DEFAULT_PT = 0.02;
const DEFAULT_COMMISSION = 0.1;

const DEFAULT_MIN_BRAND_PT = 0.0;

const DEFAULT_FONT = {
  label: 'Serif',
  value:
    'Times New Roman, Bodoni, Garamond, Minion Web, ITC Stone Serif, MS Georgia, Bitstream Cyberbit',
};

const DEFAULT_THEME_SETTINGS = {
  mainColor: '#FFFFFF',
  secondaryColor: '#000000',
  mainFont: DEFAULT_FONT.value,
  secondaryFont: DEFAULT_FONT.value,
};

const REG_EXP_COLOR = /^#[A-Fa-f0-9]{6}/;

const SUPABASE_TABLES = {
  eventTypes: 'event_types',
  countries: 'countries',
  countryEventTypes: 'country_event_types',
  eventTypeMarketType: 'event_type_market_types',
  competitions: 'competitions',
  events: 'events',
  marketTypes: 'market_types',
  venues: 'venues',
  brandEventTypeConfigs: 'brand_event_type_configs',
  brandEventsConfigs: 'brand_events_configs',
  brandVenuesConfigs: 'brand_venues_configs',
  brandEventMarketTypesConfigs: 'brand_event_market_types_configs',
  brandCountriesConfigs: 'brand_countries_configs',
  brandCompetitionsConfigs: 'brand_competitions_configs',
  brandCompetitionDateConfigs: 'brand_competition_date_configs',
  playerEventTypeConfigs: 'player_event_type_configs',
  playerEventsConfigs: 'player_events_configs',
  playerVenuesConfigs: 'player_venues_configs',
  playerEventMarketTypesConfigs: 'player_event_market_types_configs',
  playerCountriesConfigs: 'player_countries_configs',
  playerCompetitionsConfigs: 'player_competitions_configs',
  playerCompetitionDateConfigs: 'player_competition_date_configs',
};

const TIMESTAMP_TZ_FORMAT = 'yyyy-MM-dd HH:mm:ssXXXXX';

const DEFAULT_RISK_LEVEL = 'low';

const DEFAULT_STATUS = 'active';

const RACING_EVENT_TYPE_IDS = ['7', '4339'];

const SPORT_COUNTRY_EVENT_TYPE_IDS = ['1', '7', '4339'];

const EXCHANGE_DAY_RANGE = 30;

const SPORTLINE_DEFAULT_DAY_RANGE = 7;

const SUPABASE_STORAGE_AUTH_KEY = 'supabaseSession';

const DEFAULT_PAGINATION_LIMIT = 10;

const DEFAULT_PAGE = 0;

const DEFAULT_ROWS_PER_PAGE = [5, 10, 15, 25, 50, 100];

const DEFAULT_PAGE_SIZE = 100;
const BETTING_OVERVIEW_PAGE_SIZE = 100;

const SESSION_EXPIRES_IN = new Big(60)
  .times(60)
  .times(24)
  .times(6)
  .times(1000)
  .toNumber(); // session/cookie s expiration - 6 days.

const SESSION_KEY = '__session_backoffice';

const PL_REPORT_BASE_URL = 'reports/pl-report';
const PL_REPORT_DETAILED_BASE_URL = 'reports/pl-report-detailed';
const PL_EVENT_HISTORY_BASE_URL = 'reports/pl-event-history';

const DEFAULT_MIN_COLUMN_WIDTH = 150;

const DEFAULT_STATE_TIME = 600000; // 1 min

const EXTENDED_EXCHANGE_BET_SELECT = '*';

const NETWORK_INPUT_PROPS_SETTING = { min: 0, max: 1, step: 0.01 };

const SELECT_EXTENDED_ROLES =
  'roles:backoffice_user_roles(*, userRole:role_id(*))';

const SELECT_EXTENDED_PERMISSION = 'permissions:backoffice_user_permissions(*)';

const SELECT_BACKOFFICE_USER_DETAIL = 'detail:backoffice_user_detail(*)';

const SELECT_BACKOFFICE_USER_WHITELIST =
  'whitelist:backoffice_user_whiltelists(*)';

const SELECT_BACKOFFICE_USER_BALANCE = 'user_stats(balance)';

const SELECT_EXTENDED_BACKOFFICE_USER = `*, ${SELECT_EXTENDED_ROLES}, ${SELECT_EXTENDED_PERMISSION}, ${SELECT_BACKOFFICE_USER_DETAIL}, ${SELECT_BACKOFFICE_USER_WHITELIST}, ${SELECT_BACKOFFICE_USER_BALANCE}`;

const HORSE_RACING_SPORT_ID = '7';

const GREYHOUND_RACING_SPORT_ID = '4339';

const RACING_SPORT_IDS = [HORSE_RACING_SPORT_ID, GREYHOUND_RACING_SPORT_ID];

const SOCCER_EVENT_TYPE_ID = '1';

const COUNTRY_EVENT_TYPE_IDS = [SOCCER_EVENT_TYPE_ID, ...RACING_SPORT_IDS];

const SYSTEM_TRANSACTION_PARTICIPANT = 'system';

const SELECT_EXTENDED_BRAND_DOMAIN =
  '*, verifications:brand_domain_verifications(*)';

const ASIAN_HANDICAP_TEXT = 'Asian Handicap';

const GOAL_LINES_TEXT = 'Goal Lines';

const DEFAULT_EXCHANGE_BET_CHUNK_SIZE = 300;
const SHORT_NETWORK_CONFIG_LENGTH = 3;

const DEFAULT_IDS_CHUNK_SIZE = 100;
const DEFAULT_FETCH_LIMIT_BETS = 1000;

const PROFIT_LOSS_REPORT_LEVELS = [
  'systemAdmin',
  'brand',
  'superAgent',
  'masterAgent',
  'agent',
  'player',
] as const;

const DATE_RANGE_INTERVALS = ['In Play', 'Today', 'Tomorrow', 'Future'];

const ROLES_HIERARCHY: Record<BackofficeUserRole, number> = {
  systemAdmin: 5,
  owner: 4,
  superAgent: 3,
  masterAgent: 2,
  agent: 1,
  support: 0,
  marketing: 0,
  finance: 0,
};

const HANDICAP_NAME = 'Handicap';
const GOAL_LINE_NAME = 'Goal Line';

export {
  PL_EVENT_HISTORY_BASE_URL,
  HANDICAP_NAME,
  GOAL_LINE_NAME,
  ROLES_HIERARCHY,
  DATE_RANGE_INTERVALS,
  SELECT_EXTENDED_BRAND_DOMAIN,
  MAX_COMMISSION_RATE,
  COUNTRY_EVENT_TYPE_IDS,
  RACING_SPORT_IDS,
  SYSTEM_TRANSACTION_PARTICIPANT,
  SELECT_EXTENDED_BACKOFFICE_USER,
  NETWORK_INPUT_PROPS_SETTING,
  EXTENDED_EXCHANGE_BET_SELECT,
  DEFAULT_STATE_TIME,
  DEFAULT_MIN_COLUMN_WIDTH,
  INITIAL_WIDGET_STATUS,
  SESSION_EXPIRES_IN,
  SESSION_KEY,
  DEFAULT_PAGE_SIZE,
  BETTING_OVERVIEW_PAGE_SIZE,
  DEFAULT_MIN_BRAND_PT,
  DEFAULT_PAGE,
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_PAGINATION_LIMIT,
  DEFAULT_TRANSACTION_CHUNK_SIZE,
  SUPABASE_STORAGE_AUTH_KEY,
  REG_EXP_NUMBER_ALPHABETS,
  SPORTLINE_DEFAULT_DAY_RANGE,
  EXCHANGE_DAY_RANGE,
  SPORT_COUNTRY_EVENT_TYPE_IDS,
  RACING_EVENT_TYPE_IDS,
  DEFAULT_STATUS,
  DEFAULT_RISK_LEVEL,
  TIMESTAMP_TZ_FORMAT,
  DATAGRID_DEBOUNCE_MS,
  SUPABASE_TABLES,
  REG_EXP_COLOR,
  DEFAULT_FONT,
  DEFAULT_THEME_SETTINGS,
  DEFAULT_PT,
  DEFAULT_COMMISSION,
  DEFAULT_LIMIT,
  UNSETTLED_BET_DEFAULT_VALUE,
  BRAND_OWNER_ROLE,
  MAX_PT_RATE,
  AGENT_ROLE,
  NETWORK_TREE_ROWS,
  SYSTEM_ADMIN_ROLE,
  DEFAULT_AMOUNT,
  USER_ROLES,
  DEFAULT_REGION,
  WIDGET_POSITIONS,
  DEFAULT_DATE_FORMAT,
  IMAGE_TYPES,
  RISK_LEVELS,
  REG_EXP_PASSWORD,
  DEFAULT_TIME_FORMAT,
  AGENTS_ROLES,
  REG_EXP_PHONE_NUMBER,
  WIDGET_STATUS,
  PLAYER_ROLE,
  BRAND_PENDING_ROLE,
  DEFAULT_CURRENCY,
  CURRENCY_SYMBOL,
  EXCHANGE_SETTINGS_TYPES,
  DEFAULT_PRIORITY,
  DEFAULT_LIMIT_LEVEL,
  LIMIT_LEVELS,
  PLAYER_CONFIG_NAME,
  BACKOFFICE_USER_CONFIG_NAME,
  ONLY_POSITIVE_AMOUNT_ERROR,
  DOMAIN_STATUSES,
  SUPER_AGENT_ROLE,
  MASTER_AGENT_ROLE,
  TWELVE_HOUR_TIME_FORMAT,
  PL_REPORT_BASE_URL,
  PL_REPORT_DETAILED_BASE_URL,
  USER_ROLE_MAP,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  DEFAULT_STEP_SIZE,
  DEFAULT_EXCHANGE_BET_CHUNK_SIZE,
  ASIAN_HANDICAP_TEXT,
  GOAL_LINES_TEXT,
  SHORT_NETWORK_CONFIG_LENGTH,
  DEFAULT_CHUNK_SIZE,
  DEFAULT_LIMIT_RISK_BETS,
  DEFAULT_IDS_CHUNK_SIZE,
  DEFAULT_FETCH_LIMIT_BETS,
  DEFAULT_DATE_TIME_FORMAT,
  PROFIT_LOSS_REPORT_LEVELS,
};
