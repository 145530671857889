import { useCallback } from 'react';
import useSupabase from './useSupabase';
import { SYSTEM_TRANSACTION_PARTICIPANT } from '../constants';
import mapPermissions from '../permissions/mapPermissions';

interface CheckIsTransactionUserAdminParams {
  userId: string;
}

const useCheckIsTransactionUserAdmin =
  (): typeof checkIsTransactionUserAdmin => {
    const supabase = useSupabase();

    const checkIsTransactionUserAdmin = useCallback(
      async (params: CheckIsTransactionUserAdminParams) => {
        try {
          const { userId } = params;

          if (userId === SYSTEM_TRANSACTION_PARTICIPANT) return true;

          const { data: roles } = await supabase
            .from('backoffice_user_roles')
            .select('*')
            .eq('backoffice_user_id', userId);

          const transactionSenderRoleList = mapPermissions(
            roles?.map((role) => role.role_id) || [],
          );

          const adminRoles = ['systemAdmin'];
          const transactionSenderIsAdmin = transactionSenderRoleList?.some(
            ({ role }) => {
              if (role && adminRoles.includes(role)) {
                const isSystemAdmin = role === 'systemAdmin';
                return isSystemAdmin;
              }
              return false;
            },
          );

          return transactionSenderIsAdmin;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          throw new Error(error.message);
        }
      },
      [supabase],
    );

    return checkIsTransactionUserAdmin;
  };

export default useCheckIsTransactionUserAdmin;
