import { defineMessages } from 'react-intl';

export default defineMessages({
  mostProfitable: {
    id: 'ProfitLoss.ProfitLossEventList.mostProfitable',
    defaultMessage: 'Most Profitable',
  },
  mostLosses: {
    id: 'ProfitLoss.ProfitLossEventList.mostLosses',
    defaultMessage: 'Most Losses',
  },
  id: {
    id: 'ProfitLoss.ProfitLossEventList.id',
    defaultMessage: 'ID',
  },
  date: {
    id: 'ProfitLoss.ProfitLossEventList.date',
    defaultMessage: 'Date',
  },
  sport: {
    id: 'ProfitLoss.ProfitLossEventList.sport',
    defaultMessage: 'Sport',
  },
  event: {
    id: 'ProfitLoss.ProfitLossEventList.event',
    defaultMessage: 'Event',
  },
  market: {
    id: 'ProfitLoss.ProfitLossEventList.market',
    defaultMessage: 'Market',
  },
  totalReturn: {
    id: 'ProfitLoss.ProfitLossEventList.totalReturn',
    defaultMessage: 'Combined Result',
  },
});
