import { useIntl } from 'react-intl';
import messages from './messages';
import { GridColumns } from '@mui/x-data-grid';

const propertiesColumn = {
  editable: false,
  filterable: false,
  sortable: false,
  groupable: false,
  minWidth: 50,
  flex: 0.5,
};

const useColumns = (): GridColumns => {
  const intl = useIntl();

  return [
    {
      field: 'id',
      headerName: intl.formatMessage(messages.id),
      hide: true,
    },
    {
      ...propertiesColumn,
      field: 'date',
      headerName: intl.formatMessage(messages.date),
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString('en-GB');
      },
    },
    {
      ...propertiesColumn,
      field: 'sport',
      headerName: intl.formatMessage(messages.sport),
    },
    {
      ...propertiesColumn,
      field: 'event',
      headerName: intl.formatMessage(messages.event),
    },
    {
      ...propertiesColumn,
      field: 'market',
      headerName: intl.formatMessage(messages.market),
    },
    {
      ...propertiesColumn,
      field: 'totalReturn',
      headerName: intl.formatMessage(messages.totalReturn),
      valueFormatter: (params) => {
        const value = Number(params.value);
        return value.toFixed(2);
      },
    },
  ];
};

export default useColumns;
